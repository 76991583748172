import { useState, useEffect ,useCallback} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';

import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
import DataTable from 'react-data-table-component'; 
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";
import LoanVersioning from './LoanVersioning';

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as masterAction from "../../actions/masterAction";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const EnachTransactionScheduling = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const { urlAgreementNo } = useParams();
    const { brief } = useParams();
     const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [businessDoneLoans, setBusinessDoneLoans] = useState([]);
    const [loanDetailsEMIS, setLoanDetailsEMIS] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    const [beatPlanLocation, setBeatPlanLocation] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [selectedVisitType, setSelectedVisitType] = useState([]);
    const [loanID, setLoanID] = useState('');
    const [loanDetailStatus, setLoanDetailStatus] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [versioningLoans, setVersioningLoans] = useState([]);
	const [businessDoneLoan, setBusinessDoneLoan] = useState([]);
    const [reportName, setReportName] = useState('');
    const [cronRunDateArr, setCronRunDateArr] = useState([]);
    const [cronRunTabsReloadStatus, setCronRunTabsReloadStatus] = useState(true);
    const [activeLoanTab, setActiveLoanTab] = useState(0);
    const [auditAllLoansButtonStatus, setAuditAllLoansButtonStatus] = useState(false);

    const [keyword, setKeyword] = useState('');
    const [isENACHRegistrationChecked, setIsENACHRegistrationChecked] = useState(true);

    const [userId, setUserId] = useState(''); 
    const[intentUrl, setIntentUrl] = useState('');
    const [totalOutstandingAmt, setTotalOutstandingAmt] = useState(0);
    const [loadDefaultQR, setLoadDefaultQR] = useState(false);
    const [formValues, setFormValues] = useState({
        amount: ''
    });
    const [runENACHTransactionsApis, setRunENACHTransactionsApis] = useState(false);
	
    useEffect(() => {

        if (brief !== undefined ) {
            setReportName(brief); 
        }
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        if (id !== undefined ) {
            const idVal = atob(id);            
            setLoanID(idVal); 
             setLoanDetailStatus(true);
        } 
    }, [setLoanID,id,setReportName]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Audit Report')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value); 
    };
    const handleENACHRegistrationChange = (event) => {
         setIsENACHRegistrationChecked(event.target.checked);
    };

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleTabClick = (index) => {
        setActiveLoanTab(index); // Update active tab index
        const clickedDate = cronRunDateArr[index].formatted_cron_run_date;
        
        if(clickedDate !== ''){
            setPreloaderStatus(true);
            
            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'param': "audit_all_loans", 'clicked_date': clickedDate, 'keyword' : keyword , 'isENACHRegistrationChecked':isENACHRegistrationChecked ,'brief' : 'view_transaction_scheduling'};
            const requestData = encryptData(params);
            const response = actions.getBusinessDoneLoansData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  getBusinessDoneLoansData:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    const results = result.data.data.all_business_done_loans;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    console.log("All loans  = ",resultArr);

                    // const cronRunDateResults = result.data.data.cron_run_date;
                    // const cronRunDateResultArr = Object.keys(cronRunDateResults).map((key) => cronRunDateResults[key]);
                  //  setCronRunDateArr(cronRunDateResultArr);

                    setBusinessDoneLoans(resultArr);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                } else {
                    setPreloaderStatus(false);
                    setBusinessDoneLoans([]);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  getBusinessDoneLoansData:", error);
            });
        }
    };

    const auditAllLoansButton = async () => {
        setPreloaderStatus(true);
        
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'param': "audit_all_loans", 'keyword' : keyword , 'isENACHRegistrationChecked':isENACHRegistrationChecked,'brief' : 'view_transaction_scheduling'  };
        const requestData = encryptData(params);
        const response = actions.getBusinessDoneLoansData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getBusinessDoneLoansData:", result);
            if (result.data.success === 1) {
                setAuditAllLoansButtonStatus(true);
                setPreloaderStatus(false);
                const results = result.data.data.all_business_done_loans;
                const resultArr = Object.keys(results).map((key) => results[key]);

                const cronRunDateResults = result.data.data.cron_run_date;
                const cronRunDateResultArr = Object.keys(cronRunDateResults).map((key) => cronRunDateResults[key]);
                 setCronRunDateArr(cronRunDateResultArr);

                setBusinessDoneLoans(resultArr);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
                setAuditAllLoansButtonStatus(false);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getBusinessDoneLoansData:", error);
        });
    };

    useEffect(() => {
        setPreloaderStatus(true);

        const today = new Date();
        const todayFormatted = formatDate(today);

        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setValue("start_date", todayFormatted);
        setValue("end_date", todayFormatted);

        // console.log("111111 - id = ",id);

        if (id !== undefined ) {
            setBusinessDoneLoans([]);
            setLoanDetailsEMIS([]);
            setVersioningLoans([]);
            const idVal = atob(id); 
            const agreementNo = atob(urlAgreementNo);  
            
            let reportName = '';
            if (brief !== undefined ) {
                reportName = brief; 
            }
            // alert(reportName);
            // alert(idVal);      
            setLoanID(idVal); 
             setLoanDetailStatus(true);
             setAuditAllLoansButtonStatus(true);
 
             const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted ,'loan_id' : idVal ,'loanAgreementNo' : agreementNo,'reportName' : reportName, 'keyword' : keyword , 'isENACHRegistrationChecked':isENACHRegistrationChecked,'brief' : 'view_transaction_scheduling' };
                const requestData = encryptData(params);
                const response = actions.getBusinessDoneLoansData(requestData, false);
                response.then((result) => {
                    // console.log("Promise resolved -  getBusinessDoneLoansData:", result);
                    if (result.data.success === 1) {
                        setPreloaderStatus(false);
                        const results = result.data.data.all_business_done_loans;
                        const resultArr = Object.keys(results).map((key) => results[key]);
                        setVersioningLoans(resultArr);

                        const defaultTabIndex = (resultArr.length >0) ? (resultArr.length - 1) : 0;
                        setActiveTab(defaultTabIndex);
                             
                        if (
                            results &&
                            results[0] &&
                            Object.prototype.hasOwnProperty.call(results[0], 'RepaymentSchedules')
                        ) {
                            // alert(results[0].AgreementNo);
                             setLoanDetailsEMIS(results[0].RepaymentSchedules);
                             setBusinessDoneLoan(results[0]);

                             setTotalOutstandingAmt(100);
                        }
                    } else {
                        setPreloaderStatus(false);
                        setBusinessDoneLoans([]);
                        setLoanDetailsEMIS([]);
                        setBusinessDoneLoan([]);
                    }
                }).catch((error) => {
                    setPreloaderStatus(false);
                    console.error("Promise rejected -  getBusinessDoneLoansData:", error);
                });
        }else{
            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted, 'keyword' : keyword , 'isENACHRegistrationChecked':isENACHRegistrationChecked,'brief' : 'view_transaction_scheduling'  };
            const requestData = encryptData(params);
            const response = actions.getBusinessDoneLoansData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  getBusinessDoneLoansData:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    const results = result.data.data.all_business_done_loans;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    console.log("All loans  = ",resultArr);

                    setBusinessDoneLoans(resultArr);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                } else {
                    setPreloaderStatus(false);
                    setBusinessDoneLoans([]);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  getBusinessDoneLoansData:", error);
            });
        }

        
    }, [setBusinessDoneLoans,setLoanID,setLoanDetailsEMIS,setLoanDetailStatus,id, setBusinessDoneLoan,setVersioningLoans, setCronRunDateArr, setTotalOutstandingAmt]);

    const onSubmit = (data) => { 
         setPreloaderStatus(true);
        const params = {
            'keyword' : keyword,
            'isENACHRegistrationChecked':isENACHRegistrationChecked,
            'brief' : 'view_transaction_scheduling',
            'runENACHTransactionsApis' : runENACHTransactionsApis,  
            'start': '0',
            'length': config.MAX_RECORDS
        };

        const requestData = encryptData(params);
        const response = actions.getBusinessDoneLoansData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.all_business_done_loans;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setBusinessDoneLoans(resultArr);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
            }
         }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  lead list:", error);
        });
    };

    const btnRunENACHTransactions = () => {
        setRunENACHTransactionsApis(true); 
    };

    useEffect(() => {
        if (runENACHTransactionsApis) {
             onSubmit();
             setRunENACHTransactionsApis(false);
        }
    }, [runENACHTransactionsApis]);

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = businessDoneLoans.filter(item => {
        const AgreementNo = item.AgreementNo?.toLowerCase() || '';
        const CustomerName = item.CustomerName?.toLowerCase() || '';
        // const CustomerNo = item.CustomerNo?.toLowerCase() || '';
        return (
            AgreementNo.includes(searchQuery.toLowerCase()) ||
            CustomerName.includes(searchQuery.toLowerCase())
            // ||
            // CustomerNo.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice();
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);
     
    const exportLoansReport = async () => {
        const table = document.getElementById('exportLoansReportExcelFile');
        
        // Debugging log: Check if table is correctly selected
        console.log('Table:', table);
        
        const headersRow = table.querySelector('thead tr');
        const headers = Array.from(headersRow.querySelectorAll('th'));
        const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');
        
        // Debugging logs: Check headersRow and actionColumnIndex
        console.log('Headers Row:', headersRow);
        console.log('Action Column Index:', actionColumnIndex);
        
        if (actionColumnIndex !== -1) {
            // Debugging log: Check the header element to remove
            console.log('Header to Remove:', headers[actionColumnIndex]);
            
            headersRow.removeChild(headers[actionColumnIndex]);
        } else {
            console.log('Action column not found.');
        }
    
        TableToExcel.convert(table, {
            name: 'Audit-Report.xlsx',
            sheet: {
                name: 'Audit-Report',
            },
        });
    
        // Re-append the action column header if it was removed
        if (actionColumnIndex !== -1) {
            headersRow.appendChild(headers[actionColumnIndex]);
        }
    };

    const getLocation = (beatPlanId, btnAction, id) => {

        setPreloaderStatus(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                if(latitude !== "" && longitude !== ""){
            
                    const params = {
                        'id': id,
                        'beat_plan_id': beatPlanId,
                        'btnAction': btnAction,
                        'latitude': latitude,
                        'longitude': longitude
                    };
                    
                    const requestData = encryptData(params);
                    const response = actions.setBeatPlanStatus(requestData, false);
                    response.then((result) => {
                        if (result.data.success === 1) {
                            
                            toast.success(result.data.message, {
                                position: "top-right",
                            });
                            // setPreloaderStatus(false);
                            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': startDate, 'end_date': endDate, 'keyword' : keyword , 'isENACHRegistrationChecked':isENACHRegistrationChecked,'brief' : 'view_transaction_scheduling'  };
                            const requestData = encryptData(params);
                            const response = actions.getBusinessDoneLoansData(requestData, false);
                            response.then((result) => {
                                // console.log("Promise resolved -  getBusinessDoneLoansData:", result);
                                if (result.data.success === 1) {
                                    setPreloaderStatus(false);
                                    const results = result.data.data.all_business_done_loans;
                                    const resultArr = Object.keys(results).map((key) => results[key]);
                                    setBusinessDoneLoans(resultArr);
                                } else {
                                    setPreloaderStatus(false);
                                    setBusinessDoneLoans([]);
                                }
                            }).catch((error) => {
                                setPreloaderStatus(false);
                                console.error("Promise rejected -  getBusinessDoneLoansData:", error);
                            });
                        } else {
                            setPreloaderStatus(false);
                            toast.error(result.data.message, {
                                position: "top-right",
                            });
                        }
                    }).catch((error) => {
                        setPreloaderStatus(false);
                        console.error("Promise rejected -  setBeatPlanStatus: ", error);
                    });
                }

                setBeatPlanLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, (error) => {
                toast.error('Error getting location:', error, {
                    position: "top-right",
                });
            });
        } else {
            toast.error("Geolocation is not supported by this browser.", {
                position: "top-right",
            });
        }
    }

    useEffect(() => {
        if (cronRunDateArr.length > 0) {
            setActiveLoanTab(cronRunDateArr.length - 1);
        }
    }, [cronRunDateArr, setActiveLoanTab]);

    const goBackBtn = (event) => {
        setLoanDetailStatus(false);
        setAuditAllLoansButtonStatus(false);
        const url = `/dashboard/allcloud-loans-report`;
       // window.open(url, '_self');
        navigate(url);
      };

      const goBackBtnFromAuditAllLoans = (event) => {

        setPreloaderStatus(true);
        setAuditAllLoansButtonStatus(false);
        setCronRunDateArr([]);
        setBusinessDoneLoans([]);
        setLoanDetailsEMIS([]);
        setBusinessDoneLoan([]);
        
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'keyword' : keyword , 'isENACHRegistrationChecked':isENACHRegistrationChecked,'brief' : 'view_transaction_scheduling' };
        const requestData = encryptData(params);
        const response = actions.getBusinessDoneLoansData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getBusinessDoneLoansData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.all_business_done_loans;
                const resultArr = Object.keys(results).map((key) => results[key]);

                setBusinessDoneLoans(resultArr);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getBusinessDoneLoansData:", error);
        });
    };

      const viewDetails = (record) => {
        setLoanDetailsEMIS([]);
        setBusinessDoneLoan([]); 
        setLoanID(record.id); 
        setLoanDetailStatus(true);
        console.log("RepaymentSchedules = ",record.RepaymentSchedules);
        if (record && Object.prototype.hasOwnProperty.call(record, 'RepaymentSchedules')) {
            setLoanDetailsEMIS(record.RepaymentSchedules);
        }
        setBusinessDoneLoan(record);
      };

      const moneyFormatIndia = (number) => {
        if (number !== undefined && number !== null && !Number.isNaN(Number(number))) {
            number = parseFloat(number).toFixed(2);  // Ensure two decimal places
    
            const [integerPart, decimalPart] = number.split('.');
    
            const lastThreeDigits = integerPart.slice(-3);
            const otherDigits = integerPart.slice(0, -3);
    
            let formattedIntegerPart;
            if (otherDigits !== '') {
                formattedIntegerPart = `${otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${lastThreeDigits}`;
            } else {
                formattedIntegerPart = lastThreeDigits;
            }
    
            return `${formattedIntegerPart}.${decimalPart}`;
        }
    
        return '0.00';  // Default return value for invalid inputs
    };
    
    const formatDateForValue = (date) => {
        if (!date || Number.isNaN(new Date(date).getTime())) {
            return '';  // Return an empty string for invalid or empty dates
        }
    
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
    
        return `${day}-${month}-${year}`;
    };

    const columns = [
        {
            name: 'Agreement No',
            selector: row => row.AgreementNo,
            sortable: true,
        },
        {
            name: 'CIFID',
            selector: row => row.CIFId,
            sortable: true,
        },
        
        {
            name: 'Customer Name',
            selector: row => row.CustomerName,
            sortable: true,
        },
        {
            name: 'Customer No',
            selector: row => row.CustomerNo,
            sortable: true,
        },

        {
            name: 'E-Nach Status',
            selector: row => row.LatestEnachRegistrationStatus,
            sortable: true,
            cell: row => (
                <span
                    style={{
                        color: row.LatestEnachRegistrationStatus === 'Success' ? 'green' :
                               (row.LatestEnachRegistrationStatus  === 'Aborted' ) ? 'orange' : 'red'
                    }}
                >
                    {row.LatestEnachRegistrationStatus}
                </span>
            ),
        },
        {
            name: 'Scheduling Status',
            selector: row => row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus || 'N/A',
            sortable: true,
            cell: row => (
                <span
                    style={{
                        color: row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus  === 'Success' ? 'green' :
                               (row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus  === 'Initiated' ) ? 'orange' : 'red'
                    }}
                >
                    {row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus || 'N/A'}
                </span>
            ),
        },


        {
            name: 'Verification Status',
            selector: row => row.transaction_verification_data?.LatestEnachTransactionVerificationStatus || 'N/A',
            sortable: true,
            cell: row => (
                <span
                    style={{
                        color: row.transaction_verification_data?.LatestEnachTransactionVerificationStatus === 'Success' ? 'green' :
                               (row.transaction_verification_data?.LatestEnachTransactionVerificationStatus  === 'Initiated' ) ? 'orange' : 'red'
                    }}
                >
                    {row.transaction_verification_data?.LatestEnachTransactionVerificationStatus || 'N/A'}
                </span>
            ),
        },


        

       /* {
          name: 'Actions',
          cell: row => (
            <div className="btn-group dropstart d-none" title="View Details">
              <NavLink to={`/dashboard/allcloud-loans-report/${btoa(String(row.id))}/${btoa(String(row.AgreementNo))}`}>
                <i className="bi bi-eye" style={{ color: "black" }} />
              </NavLink>
              &nbsp;
              <NavLink to={`/dashboard/allcloud-loans-report/${btoa(String(row.id))}/${btoa(String(row.AgreementNo))}/audit`}>
                <button className="btn btn-info text-white d-none">Audit</button>
              </NavLink>
            </div>
          ),
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        }, */
    ];

    // Conditional row styles to change text color
        const conditionalRowStyles = [
            {
            when: row => row.LatestEnachRegistrationStatus === 'Success-',   
            style: {
                color: 'green',
            },
            },
            {
            when: row => row.LatestEnachRegistrationStatus === 'Aborted-',  
            style: {
                color: 'red',
            },
            },
            {
            when: row => row.LatestEnachRegistrationStatus === 'Failed-',  
            style: {
                color: 'red',
            },
            },
        ];

    const ExpandedComponent = ({ data }) => (
        <div className='row px-2 py-2'>

        {data && Object.prototype.hasOwnProperty.call(data, 'enach_registration_sms_info') && (
            <div className="col-md-6" >
                <p>
                <strong>E-NACH Proposed Amount : </strong> 
                <span className="text-success">{data.enach_registration_sms_info.proposed_amount}
                </span></p>
            </div>
            )}

           {data && Object.prototype.hasOwnProperty.call(data, 'enach_registration_sms_info') && (
            <div className="col-md-6" >
                <p>
                <strong>E-NACH Proposed Debit Date : </strong> 
                <span className="text-success">{formatDateForValue(data.enach_registration_sms_info.proposed_debit_date)}</span>
                </p>
            </div>
            )} 

            <div className="col-md-6"><p><strong>Sanction Date : </strong> {formatDateForValue(data.dateOfSanction)}</p></div>
            <div className="col-md-6"><p><strong>Disbusment Date : </strong> {formatDateForValue(data.dateOfDisbursement)}</p></div>
            <div className="col-md-6"><p><strong>Finance Id : </strong> {data.FinanceId}</p></div>
            <div className="col-md-6"><p><strong>Company Role Id : </strong> {data.CompanyRoleId} </p> </div>
            <div className="col-md-6"><p><strong>Loan Type : </strong> {data.LoanType} </p> </div>
            <div className="col-md-6"><p><strong>Asset Id : </strong> {data.AssetId} </p> </div>
            <div className="col-md-6"><p><strong>Total Amount : </strong> {moneyFormatIndia(data.TotalAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Duration : </strong> {data.Duration} </p> </div>
            <div className="col-md-6"><p><strong>Interest (%) : </strong> {data.Interest} </p> </div>
            <div className="col-md-6"><p><strong>EMI : </strong> {moneyFormatIndia(data.EMI)} </p> </div>
            <div className="col-md-6"><p><strong>Yearly Interest (%) : </strong> {data.YearlyInterest} </p> </div>
            <div className="col-md-6"><p><strong>Start Date : </strong> {formatDateForValue(data.StartDate)} </p> </div>
            <div className="col-md-6"><p><strong>Loan Closure Date : </strong> {formatDateForValue(data.LoanClosureDate)} </p> </div>
            <div className="col-md-6"><p><strong>DPD Days : </strong> {data.DPDDays} </p> </div>
            <div className="col-md-6"><p><strong>Future Value : </strong> {data.FutureValue} </p> </div>
            <div className="col-md-6"><p><strong>Insurance Amount : </strong> {moneyFormatIndia(data.InsuranceAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Insurance Duration : </strong> {data.InsuranceDuration} </p> </div>
            <div className="col-md-6"><p><strong>Indicative ROI : </strong> {data.IndicativeROI} </p> </div>
            <div className="col-md-6"><p><strong>Send SMS : </strong> {data.SendSMS} </p> </div>
            <div className="col-md-6"><p><strong>Scheme Id : </strong> {data.SchemeId} </p> </div>
            <div className="col-md-6"><p><strong>EMIType Id : </strong> {data.EMITypeId} </p> </div>
            <div className="col-md-6"><p><strong>LPC Amount : </strong> {moneyFormatIndia(data.LPCAmount)} </p> </div>
            <div className="col-md-6"><p><strong>LPC Type : </strong> {data.LPCType} </p> </div>
            <div className="col-md-6"><p><strong>LPC Interest : </strong> {data.LPCInterest} </p> </div>
            <div className="col-md-6"><p><strong>Installment Type Id : </strong> {data.InstallmentTypeId} </p> </div>
            <div className="col-md-6"><p><strong>ROI Duration : </strong> {data.ROIDuration} </p> </div>
            <div className="col-md-6"><p><strong>Sub Loan Type For PL : </strong> {data.SubLoanTypeForPL} </p> </div>
            <div className="col-md-6"><p><strong>Loan Category : </strong> {data.LoanCategory} </p> </div>
            <div className="col-md-6"><p><strong>Down Payment : </strong> {moneyFormatIndia(data.DownPayment)} </p> </div>
            <div className="col-md-6"><p><strong>Advance EMI : </strong> {data.AdvanceEMI} </p> </div>
            <div className="col-md-6"><p><strong>Loan Segment Id : </strong> {data.LoanSegmentId} </p> </div>
            <div className="col-md-6"><p><strong>UTR No : </strong> {data.UTRNo} </p> </div>
            <div className="col-md-6"><p><strong>Disbursement Status : </strong> {data.DisbursementStatus} </p> </div>
            <div className="col-md-6"><p><strong>Nach StatusId : </strong> {data.NachStatusId} </p> </div>
            <div className="col-md-6"><p><strong>UMRN Number : </strong> {data.UMRNNumber} </p> </div>
            <div className="col-md-6"><p><strong>Status Id : </strong> {data.StatusId} </p> </div>
            <div className="col-md-6"><p><strong>EMI Start Date : </strong> {formatDateForValue(data.EMIStartDate)} </p> </div>
            <div className="col-md-6"><p><strong>EMI End Date : </strong> {formatDateForValue(data.EMIEndDate)} </p> </div>
            <div className="col-md-6"><p><strong>Next Payment Date : </strong> {formatDateForValue(data.NextPaymentDate)} </p> </div>
            <div className="col-md-6"><p><strong>Next EMI Due Date : </strong> {formatDateForValue(data.NextEMIDueDate)} </p> </div>
            <div className="col-md-6"><p><strong>Mode Of RePayment Id : </strong> {data.ModeOfRePaymentId} </p> </div>
            <div className="col-md-6"><p><strong>No Of Paid EMI : </strong> {data.NoOfPaidEMI} </p> </div>
            <div className="col-md-6"><p><strong>Total Received Amt : </strong> {moneyFormatIndia(data.TotalReceivedAmt)} </p> </div>
            <div className="col-md-6"><p><strong>Total Principle Recvd : </strong> {moneyFormatIndia(data.TotalPrincipleRecvd)} </p> </div>
            <div className="col-md-6"><p><strong>Total Interest Paid : </strong> {moneyFormatIndia(data.TotalInterestPaid)} </p> </div>
            <div className="col-md-6"><p><strong>EMI DueCount : </strong> {data.EMIDueCount} </p> </div>
            <div className="col-md-6"><p><strong>Total Principal Due : </strong> {moneyFormatIndia(data.TotalPrincipalDue)} </p> </div>
            <div className="col-md-6"><p><strong>Total Interest Due : </strong> {moneyFormatIndia(data.TotalInterestDue)} </p> </div>
            <div className="col-md-6"><p><strong>Total VAS Dues : </strong> {moneyFormatIndia(data.TotalVASDues)} </p> </div>
            <div className="col-md-6"><p><strong>Total Due Amount : </strong> {moneyFormatIndia(data.TotalDueAmount)} </p> </div>
            <div className="col-md-6"><p><strong>LPI Dues : </strong> {moneyFormatIndia(data.LPIDues)} </p> </div>
            <div className="col-md-6"><p><strong>Default Chrages : </strong> {moneyFormatIndia(data.DefaultChrages)} </p> </div>
            <div className="col-md-6"><p><strong>Total EMI Overdue Amount : </strong> {moneyFormatIndia(data.TotalEMIOverdueAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Total Loan Overdue Amount : </strong> {moneyFormatIndia(data.TotalLoanOverdueAmount)} </p> </div>
            <div className="col-md-6"><p><strong>Loan Total Due : </strong> {moneyFormatIndia(data.LoanTotalDue)} </p> </div>
            <div className="col-md-6"><p><strong>Last updated : </strong> {data.cronRunDate} </p> </div>
        </div>
    );

    const [open, setOpen] = React.useState(false);
    const btnOpenUploadMissingData = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedItrFiles([]); // Reset selected ITR files
    };
    const [selectedItrFiles, setSelectedItrFiles] = useState([]);

    const handleItrFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
                base64Files.push({ file: base64Data, name: fileName });
                if (base64Files.length === files.length) {
                    setSelectedItrFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    

    const handleFormSubmit = (data) => {

        if (!selectedItrFiles || selectedItrFiles.length === 0) {
            toast.error("No file has been selected.", {
                position: "top-right",
            });
        } else {

            const params = {"upload_file": selectedItrFiles };

            // setPreloaderStatus(true);
            const requestData = encryptData(params);
            const response = actions.uploadMissingData(requestData, navigate);

            response.then((result) => {
                // console.log("Promise resolved - uploadLeads form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setPreloaderStatus(false);
                    reset();
                    setOpen(false);
                    setSelectedItrFiles([]);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                    setPreloaderStatus(false);
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const defaultQRCall = useCallback(async () => {

        if( !loadDefaultQR && totalOutstandingAmt > 0){
            const params = { 'amount':totalOutstandingAmt, "AgreementNo" : businessDoneLoan.AgreementNo };
            if (userId !== '') {
                params.user_id = userId;
            }
        
            setIntentUrl('');
            // console.log("form data = ", params);
            const requestData = encryptData(params);            
            try {
                const result = await actions.generateQRcode(requestData);
                setLoadDefaultQR(true);                
                if (result.data.success === 1) {
                    if (result.data.intent_url !== '') {
                        setIntentUrl(result.data.intent_url);
                        toast.success("QR code generated successfully.", {
                            position: "top-right",
                        });
                    } else {
                        toast.error("You can't generate QR code, Please try again!", {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            } catch (error) {
                toast.error(error.message || "An error occurred", {
                    position: "top-right",
                });
            }
        }
    }, [userId, formValues, loadDefaultQR, totalOutstandingAmt]);
    
    useEffect(() => {
        if (!loadDefaultQR) {
           defaultQRCall();
        }
    }, [loadDefaultQR, defaultQRCall, totalOutstandingAmt]);

    
    const handleSubmitForm = (data) => {
        const params = { 'amount':  formValues.amount, "AgreementNo" : businessDoneLoan.AgreementNo };
        if (userId !== '') {
            params.user_id = userId;
        }
        setIntentUrl('');
        // console.log("form data = ", params);
        const requestData = encryptData(params);
        const response = actions.generateQRcode(requestData);
        response.then((result) => {
             if (result.data.success === 1) {
                if (result.data.intent_url !== '') {  
                    setIntentUrl(result.data.intent_url);  
                    toast.success("QR code generated sucessfully.", {
                        position: "top-right",
                    });
                } else {
                    toast.error("You can't generate QR code, Please try again!", {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
             toast.error(error, {
                position: "top-right",
            });
        });
    };   
    const handleChange = (e) => { 
       // setIntentUrl('');
        const { name, value } = e.target;      
        setFormValues({
            ...formValues,
            [name]: value,
        });         
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

   
    return (
        <>  
            <Helmet>
                <title>View Transaction Scheduling | Ckers</title>
            </Helmet>

            <Container>
                
                <div id='main-wrap'  className={loanDetailStatus ? "d-none mb-3 mmt-7 filter" : "mb-3 mmt-7 filter"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row mx-0'>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="keyword">
                                        <span>Keyword</span>
                                        <input type='text' name='keyword' id='keyword' className='form-control'{...register('keyword')} placeholder="Enter your value here..." onChange={handleKeywordChange}/>
                                    </label>
                                </div>
                            </div>

                            <div className='col d-none'>
                                <div className='form-group'>
                                    <label htmlFor="keyword">
                                        <span>E-NACH Done In LMS</span>
                                        <input type='checkbox' name='enach_registration' id='enach_registration' {...register('enach_registration')} onChange={handleENACHRegistrationChange}/>
                                    </label>
                                </div>
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Search</button>
                                </div>

                                
                            </div>

                            <div className='col'>
                                <div className='form-group mb-2 '>
                                    <span className='d-block'>&nbsp;</span>
                                    <button onClick={btnRunENACHTransactions}
                                        type="button"
                                        className="btn btn-info text-white"
                                    >
                                        Run E-NACH Transactions
                                    </button> 
                                </div>

                                <div className='form-group mb-2 d-none'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button onClick={btnOpenUploadMissingData}
                                        type="button"
                                        className="btn btn-info text-white"
                                    >
                                        Upload Missing Data
                                    </button> 
                                </div>

                                <div className='form-group mb-2 d-none'> 
                                     <span className='d-block'>&nbsp;</span>
                                    <NavLink to={`/dashboard/enach-transaction-scheduling`}>
                                    <button
                                        type="button"
                                        className="btn btn-primary text-white"
                                    >
                                        View Transaction Scheduling
                                    </button>
                                    </NavLink>
                                </div>
                            </div>

                            <div className="d-flex justify-content-end">
                                <div className={auditAllLoansButtonStatus ? "col" : "col d-none"}>
                                    <button onClick={goBackBtnFromAuditAllLoans}
                                        type="button"
                                        className="badge text-bg-success text-white"
                                    >
                                        Go Back
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>

                <div id="main-wrap">
                    <div className="row justify-content-between">
                         

                        <div className="col-md-7">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                            View Transaction Scheduling {businessDoneLoan.AgreementNo !== undefined && (
                                    <>
                                        ({businessDoneLoan.AgreementNo})
                                    </>
                                )}
                            </Typography> 
                            
                            <div className={auditAllLoansButtonStatus ? "d-none" : "m-1 d-none"}>
                                <button className="btn btn-info text-white" onClick={() => auditAllLoansButton()}>
                                    AUDIT ALL LOANS
                                </button>
                            </div>
                        </div>


                        <div className='col-md-5 text-end ps-lg-5'>                            
                        {(!loanDetailStatus) && (
                            <>
                            <div className="form-group d-flex justify-content-end align-items-center">
                                <label htmlFor="search" className='w-100 d-none'>
                                    {/* Search : */}
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder=""
                                        id="search"
                                        value={searchQuery}
                                        onChange={handleSearchChange}
                                    />
                                </label>
                                <button className="btn btn-info text-white ms-3 d-none" onClick={() => exportLoansReport()}>
                                    Export
                                </button>
                                
                            </div>
                            <div className="float-left mb-2"><b>Total Records : {businessDoneLoans.length}</b></div>
                            </>
                        )}

                        {(loanDetailStatus) && ( 
                            <div>                      
                                <button onClick={goBackBtn} 
                                    type="button"
                                    className= "badge text-bg-success text-white float-right"
                                    > 
                                Go Back
                                </button> 
                                </div>                                
                            )}
                           
                        </div>
                        
                        {(!loanDetailStatus) && (
                            <>
                                <div className='row'>
                                    <div className="col-md-12 mt-1">
                                        <div className="tabs">
                                            {cronRunDateArr.map((tab, index) => (
                                                <button
                                                    key={index}
                                                    className={activeLoanTab === index ? 'btn active activTab btn-primary' : 'btn'}
                                                    onClick={() => handleTabClick(index)}
                                                >
                                                    {tab.formatted_cron_run_date}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </>
                        )}
                           
                    </div>
                    
                    <div className={!loanDetailStatus ? "d-none row justify-content-between" : "row justify-content-between"}>
                        <div className="col-md-6">
                            <span>Overdue Amount : {moneyFormatIndia(businessDoneLoan.overdueAmount)} </span>
                        </div>
                        <div className="col-md-6">
                            <span>DPD : {businessDoneLoan.dpd} </span>
                        </div>
                        <div className="col-md-6">
                            <span>Principal Outstanding : {moneyFormatIndia(businessDoneLoan.principalOutstanding)} </span>
                        </div>
                        <div className="col-md-6">
                            <span>Interest Outstanding : {moneyFormatIndia(businessDoneLoan.interestOutstanding)} </span>
                        </div>
                        <div className="col-md-6">
                            <span>Last updated on : {businessDoneLoan.cronRunDate}</span>
                        </div>

                        <div className="col-md-12 row" >
                            <span>Total Outstanding : {moneyFormatIndia( totalOutstandingAmt )} </span>
                            
                             
                            
                            <div className="col-md-12" style={{ background:"honeydew" }}>
                             
 
<form onSubmit={handleSubmit(handleSubmitForm)}>
        <div className='row'>
            
            <div className='col-md-6'>
                <div className='form-group'>
                    <label htmlFor="name">
                        <span>Pay with Other Amount (INR)<a style={{ color: "red" }}>*</a></span>
                        <input
                            type='number'
                            id='amount'
                            name='amount'
                            value={formValues.amount}
                            className='form-control'
                            placeholder=''
                            {...register('amount', {})}
                            /* {...register('amount', { required: formValues.amount === '' })} */
                            onChange={handleChange}
                        />
                        {errors.amount && <span className="required">This field is required.</span>}

                    </label>
                </div>

                    <div className='col-md-12'>
                    <div className='form-group mb-2'>
                        <button className='btn btn-primary'>Generate QR</button>
                    </div>
                </div>
            </div>    
            
            <div className='col-md-6'>
                <div className='form-group'>
                        <div className="float-right">
                        { (intentUrl!=='') && (<QRCode  value={intentUrl} size={150} /> )  }
                    </div>
            </div>
            </div>
        </div>
    </form>

                            </div>
                           
                        </div>
                    </div>

                    {(!loanDetailStatus) ? (
                        <DataTable
                            // title="My DataTable with Expandable Rows"
                            columns={columns}
                            data={businessDoneLoans}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            conditionalRowStyles={conditionalRowStyles}
                            // pagination
                        />
                    ) : (
                        <>
                        <div className="mt-4">

                        <div className="row">

                        <div className="col-md-12 mt-4">  
                            <div className="tabs">
                                {reportName !=='' && versioningLoans.map((tab, index) => (
                                 
                                <button                                   
                                    className={activeTab === index ? 'btn active activTab btn-primary' : 'btn'}
                                    onClick={() => setActiveTab(index)}
                                >
                                    {tab.formated_cron_run_date}
                                </button>
                                 
                                ))}
                            </div>
                            </div>
                           <div className="col-md-12">   
                            <div className="tab-content">

                            {versioningLoans.map((vitem, vindex) => { 
                                return (
                                    <> 
                                        <div  key={vindex} className={activeTab === vindex ? 'width100' : 'width100 d-none'}> 
                                            <LoanVersioning vitem={vitem} />
                                        </div>
                                     </>
                                );
                                })}
                            </div> 
                            </div> 
                            </div>
                        </div>
                    </>)}

                    <div className="table-wrap table-responsive d-none">

                        {(!loanDetailStatus) ? (
                            <table className="table table-hover" id="exportLoansReportExcelFile">
                                <thead>
                                    <tr>
                                        <th className="text-nowrap">Agreement No</th>
                                        <th className="text-nowrap">CIFID</th>
                                        <th className="text-nowrap">Customer Name</th>
                                        <th className="text-nowrap">Customer No</th>
                                        <th className="text-nowrap">Sanction Date</th>
                                        <th className="text-nowrap">Disbusment Date</th>
                                        <th className="text-nowrap">Emi Amount</th>
                                        <th className="text-nowrap">Amount Of Loan</th>
                                        <th className="text-nowrap">Current Outstanding</th>
                                        <th className="text-nowrap">Overdue Amount</th>
                                        <th className="text-nowrap">DPD</th>
                                        <th className="text-nowrap">Principal Outstanding</th>
                                        <th className="text-nowrap">Interest Outstanding</th>
                                        <th className="text-nowrap">File No</th>
                                        <th className="text-nowrap">Registration No</th>
                                        <th className="text-nowrap">Company</th>
                                        <th className="text-nowrap">Branch</th>
                                        <th className="text-nowrap">Centre</th>
                                        <th className="text-nowrap">Entity Type</th>
                                        <th className="text-nowrap">Loan Type</th>
                                        <th className="text-nowrap">Loan Category</th>
                                        <th className="text-nowrap">Guarantor Name</th>
                                        <th className="text-nowrap">Guarantor No</th>
                                        <th className="text-nowrap">CoBorrower1 Name</th>
                                        <th className="text-nowrap">CoBorrower1 No.</th>
                                        <th className="text-nowrap">Guarantor1 Name</th>
                                        <th className="text-nowrap">Guarantor1 No</th>
                                        <th className="text-nowrap">Mfg. Year</th>
                                        <th className="text-nowrap">Fuel Type</th>
                                        <th className="text-nowrap">Agreement Date</th>
                                        <th className="text-nowrap">First Due Date</th>
                                        <th className="text-nowrap">Installments</th>
                                        <th className="text-nowrap">Frequency</th>
                                        <th className="text-nowrap">Total Amt</th>
                                        <th className="text-nowrap">First EMI</th>
                                        <th className="text-nowrap">Interest (%)</th>
                                        <th className="text-nowrap">IndicativeROI (%)</th>
                                        <th className="text-nowrap">IRR</th>
                                        <th className="text-nowrap">Deductions</th>
                                        <th className="text-nowrap">Make</th>
                                        <th className="text-nowrap">Class</th>
                                        <th className="text-nowrap">Variant</th>
                                        <th className="text-nowrap">Vehicle Type</th>
                                        <th className="text-nowrap">Engine No</th>
                                        <th className="text-nowrap">Chassis No</th>
                                        <th className="text-nowrap">Source Type</th>
                                        <th className="text-nowrap">Source Name</th>
                                        <th className="text-nowrap">Organization Name</th>
                                        <th className="text-nowrap">Date Of Incorporation</th>
                                        <th className="text-nowrap">Collection Executive</th>
                                        <th className="text-nowrap">Lead Owner</th>
                                        <th className="text-nowrap">Hypothecated To</th>
                                        <th className="text-nowrap">Renewed</th>
                                        <th className="text-nowrap">Product</th>
                                        <th className="text-nowrap">Area</th>
                                        <th className="text-nowrap">Landmark</th>
                                        <th className="text-nowrap">Loan Segment</th>
                                        <th className="text-nowrap">Scheme Name</th>
                                        <th className="text-nowrap">Simple Interest</th>
                                        <th className="text-nowrap">Dealer Name</th>
                                        <th className="text-nowrap">Last Due Date</th>
                                        <th className="text-nowrap">Last Installment Paid Date</th>
                                        <th className="text-nowrap">LPC Calculation Type</th>
                                        <th className="text-nowrap">LP Interest</th>
                                        <th className="text-nowrap">Disbursement Amount</th>
                                        <th className="text-nowrap">Disbursement Date</th>
                                        <th className="text-nowrap">Disbursement Status</th>
                                        <th className="text-nowrap">Mode Of RePayment</th>
                                        <th className="text-nowrap">Invoice No</th>
                                        <th className="text-nowrap">Credit Score</th>
                                        <th className="text-nowrap">CKYC Id</th>
                                        <th className="text-nowrap">Loan Amount</th>
                                        <th className="text-nowrap">Finance Tags</th>
                                        <th className="text-nowrap">Region</th>
                                        <th className="text-nowrap">Father/Spouse</th>
                                        <th className="text-nowrap">Funding Type</th>
                                        <th className="text-nowrap">Status</th>
                                        <th className="text-nowrap">Arrears</th>
                                        <th className="text-nowrap">Arrear Received</th>
                                        <th className="text-nowrap">Prev Adv Coll</th>
                                        <th className="text-nowrap">Month TBC</th>
                                        <th className="text-nowrap">Month TBC Received</th>
                                        <th className="text-nowrap">Total Dues</th>
                                        <th className="text-nowrap">Month Collected</th>
                                        <th className="text-nowrap">Gross TBC</th>
                                        <th className="text-nowrap">Gross Demand</th>
                                        <th className="text-nowrap">Gross Collected</th>
                                        <th className="text-nowrap">Coll/Demand (%)</th>
                                        <th className="text-nowrap">Receipt Count</th>
                                        <th className="text-nowrap">Advance Coll.</th>
                                        <th className="text-nowrap">EMI Due Count</th>
                                        <th className="text-nowrap">Running EMI Count</th>
                                        <th className="text-nowrap">Paid EMIs</th>
                                        <th className="text-nowrap">Balance EMIs</th>
                                        <th className="text-nowrap">LPC Dues</th>
                                        <th className="text-nowrap">Bucket</th>
                                        <th className="text-nowrap">Last Rcvd Date</th>
                                        <th className="text-nowrap">Cumm VAS</th>
                                        <th className="text-nowrap">VAS Collected</th>
                                        <th className="text-nowrap">VAS Due</th>
                                        <th className="text-nowrap">Cumm Handloan</th>
                                        <th className="text-nowrap">Handloan Collected</th>
                                        <th className="text-nowrap">Handloan Due</th>
                                        <th className="text-nowrap">Future Principal Dues</th>
                                        <th className="text-nowrap">Total Future Finance Due</th>
                                        <th className="text-nowrap">Insurance Policy</th>
                                        <th className="text-nowrap">Insurance Expiry</th>
                                        <th className="text-nowrap">Seize Date</th>
                                        <th className="text-nowrap">Registration Certificate</th>
                                        <th className="text-nowrap">SMS Enabled</th>
                                        <th className="text-nowrap">Seize Status</th>
                                        <th className="text-nowrap">Owner Name</th>
                                        <th className="text-nowrap">Principal Received</th>
                                        <th className="text-nowrap">Interest Received</th>
                                        <th className="text-nowrap">LPC Collected</th>
                                        <th className="text-nowrap">LPC Discount</th>
                                        <th className="text-nowrap">HP Endorse</th>
                                        <th className="text-nowrap">HP Endorse By</th>
                                        <th className="text-nowrap">Total Pending EMIs</th>
                                        <th className="text-nowrap">No of Dues</th>
                                        <th className="text-nowrap">Last Instalment Paid Date</th>
                                        <th className="text-nowrap">Last EMI Paid</th>
                                        <th className="text-nowrap">NPA</th>
                                        <th className="text-nowrap">Legal</th>
                                        <th className="text-nowrap">Postcode</th>
                                        <th className="text-nowrap">Last Rcvd Amount</th>
                                        <th className="text-nowrap">LPC Balance</th>


                                        <th className="text-nowrap">Disbursment Chq.No</th>
                                        <th className="text-nowrap">Down Payment</th>
                                        <th className="text-nowrap">Advanve EMI</th>
                                        <th className="text-nowrap">LCC (%)</th>
                                        <th className="text-nowrap">Loan Cycle No</th>
                                        <th className="text-nowrap">LTV</th>
                                        <th className="text-nowrap">Name of the Insurance Co.</th>
                                        <th className="text-nowrap">Policy Issued Branch</th>
                                        <th className="text-nowrap">ID Proof</th>
                                        <th className="text-nowrap">Future Flow Amt in Rs.</th>
                                        <th className="text-nowrap">Processing Fees</th>
                                        <th className="text-nowrap">Last updated</th>
                                        <th className="text-right">&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {businessDoneLoans.map((item, index) => {
                                        let deleteLoanStatus = false;

                                        if (item && Object.prototype.hasOwnProperty.call(item, 'loan_status') && item.loan_status === 'delete') {
                                            deleteLoanStatus = true; // Apply red color class
                                        }

                                        // Safely parse the JSON data and ensure it's an array
                                        let detailsDueApiResponse = [];
                                        try {
                                            detailsDueApiResponse = JSON.parse(item.details_due_api_response || '[]');
                                            if (!Array.isArray(detailsDueApiResponse)) {
                                                detailsDueApiResponse = [];
                                            }
                                        } catch (error) {
                                            // console.error('Failed to parse details_due_api_response:', error);
                                        }

                                        // Ensure the data has at most 15 elements
                                        const dataToDisplay = [
                                            ...detailsDueApiResponse.slice(0, 15),
                                            ...new Array(Math.max(15 - detailsDueApiResponse.length, 0)).fill("")
                                        ];

                                        return (
                                            <>
                                                {deleteLoanStatus ? (<>
                                                    <tr key={index} className={deleteLoanStatus ? 'deletedRow' : ''}>
                                                        <td className={deleteLoanStatus ? 'deletedRow' : ''}>{item.AgreementNo}</td>
                                                        <td colSpan={14} className={deleteLoanStatus ? 'deletedRow text-center py-3 fw-bold' : ''}>Deleted</td>
                                                    </tr>
                                                </>) : (<>
                                                    <tr key={index}  >
                                                        <td >{item.AgreementNo} </td>

                                                        <td>
                                                            {item.new_CIFId ? (
                                                                <>
                                                                    <div className="text-danger">Old:{(item.old_CIFId !== null && item.old_CIFId !== '') ? item.old_CIFId : ''}</div>
                                                                    <div className="text-success">New:{(item.new_CIFId !== null && item.new_CIFId !== '') ? item.new_CIFId : ''}</div>
                                                                </>
                                                            ) : (
                                                                item.CIFId
                                                            )}
                                                        </td>

                                                        <td>
                                                            {item.new_CustomerName ? (
                                                                <>
                                                                    <div className="text-danger">Old:{(item.old_CustomerName !== null && item.old_CustomerName !== '') ? item.old_CustomerName : ''}</div>
                                                                    <div className="text-success">New:{(item.new_CustomerName !== null && item.new_CustomerName !== '') ? item.new_CustomerName : ''}</div>
                                                                </>
                                                            ) : (
                                                                item.CustomerName
                                                            )}
                                                        </td>

                                                        <td>
                                                            {item.new_CustomerNo ? (
                                                                <>
                                                                    <div className="text-danger">Old:{(item.old_CustomerNo !== null && item.old_CustomerNo !== '') ? item.old_CustomerNo : ''}</div>
                                                                    <div className="text-success">New:{(item.new_CustomerNo !== null && item.new_CustomerNo !== '') ? item.new_CustomerNo : ''}</div>
                                                                </>
                                                            ) : (
                                                                item.CustomerNo
                                                            )}
                                                        </td>

                                                        <td>
                                                            {item.new_dateOfSanction ? (
                                                                <>
                                                                    <div className="text-danger">Old:{(item.old_dateOfSanction !== null && item.old_dateOfSanction !== '') ? item.old_dateOfSanction : ''}</div>
                                                                    <div className="text-success">New:{(item.new_dateOfSanction !== null && item.new_dateOfSanction !== '') ? item.new_dateOfSanction : ''}</div>
                                                                </>
                                                            ) : (
                                                                item.dateOfSanction
                                                            )}
                                                        </td>

                                                        <td>
                                                            {item.new_dateOfDisbursement ? (
                                                                <>
                                                                    <div className="text-danger">Old:{(item.old_dateOfDisbursement !== null && item.old_dateOfDisbursement !== '') ? item.old_dateOfDisbursement : ''}</div>
                                                                    <div className="text-success">New:{(item.new_dateOfDisbursement !== null && item.new_dateOfDisbursement !== '') ? item.new_dateOfDisbursement : ''}</div>
                                                                </>
                                                            ) : (
                                                                item.dateOfDisbursement
                                                            )}
                                                        </td>

                                                        <td>
                                                            {item.new_emiAmount ? (
                                                                <>
                                                                    <div className="text-danger">Old:{(item.old_emiAmount !== null && item.old_emiAmount !== '') ? item.old_emiAmount : ''}</div>
                                                                    <div className="text-success">New:{(item.new_emiAmount !== null && item.new_emiAmount !== '') ? item.new_emiAmount : ''}</div>
                                                                </>
                                                            ) : (
                                                                moneyFormatIndia(item.emiAmount)
                                                            )}
                                                        </td>

                                                        <td>
                                                            {item.new_amountOfLoan ? (
                                                                <>
                                                                    <div className="text-danger">Old:{(item.old_amountOfLoan !== null && item.old_amountOfLoan !== '') ? item.old_amountOfLoan : ''}</div>
                                                                    <div className="text-success">New:{(item.new_amountOfLoan !== null && item.new_amountOfLoan !== '') ? item.new_amountOfLoan : ''}</div>
                                                                </>
                                                            ) : (
                                                                moneyFormatIndia(item.TotalAmount)
                                                            )}
                                                        </td>

                                                        <td>{moneyFormatIndia(item.currentOutstanding)}</td>

                                                        <td>{moneyFormatIndia(item.overdueAmount)}</td>

                                                        <td>{item.dpd}</td>

                                                        <td>{moneyFormatIndia(item.principalOutstanding)}</td>

                                                        <td>{moneyFormatIndia(item.interestOutstanding)}</td>
                                                        <td>{item.FileNo}</td>
                                                        <td>{item.RegistrationNo}</td>
                                                        <td>{item.Company}</td>
                                                        <td>{item.Branch}</td>
                                                        <td>{item.Centre}</td>
                                                        <td>{item.EntityType}</td>
                                                        <td>{item.LoanType}</td>
                                                        <td>{item.LoanCategory}</td>
                                                        <td>{item.GuarantorName}</td>
                                                        <td>{item.GuarantorNo}</td>
                                                        <td>{item.CoBorrower1Name}</td>
                                                        <td>{item.CoBorrower1No}</td>
                                                        <td>{item.Guarantor1Name}</td>
                                                        <td>{item.Guarantor1No}</td>
                                                        <td>{item.MfgYear}</td>
                                                        <td>{item.FuelType}</td>
                                                        <td>{item.AgreementDate}</td>
                                                        <td>{item.FirstDueDate}</td>
                                                        <td>{item.Installments}</td>
                                                        <td>{item.Frequency}</td>
                                                        <td>{moneyFormatIndia(item.TotalAmt)}</td>
                                                        <td>{moneyFormatIndia(item.FirstEMI)}</td>
                                                        <td>{item.Interest}</td>
                                                        <td>{item.IndicativeROI}</td>
                                                        <td>{item.IRR}</td>
                                                        <td>{item.Deductions}</td>
                                                        <td>{item.Make}</td>
                                                        <td>{item.Class}</td>
                                                        <td>{item.Variant}</td>
                                                        <td>{item.VehicleType}</td>
                                                        <td>{item.EngineNo}</td>
                                                        <td>{item.ChassisNo}</td>
                                                        <td>{item.SourceType}</td>
                                                        <td>{item.SourceName}</td>
                                                        <td>{item.OrganizationName}</td>
                                                        <td>{item.DateOfIncorporation}</td>
                                                        <td>{item.CollectionExecutive}</td>
                                                        <td>{item.LeadOwner}</td>
                                                        <td>{item.HypothecatedTo}</td>
                                                        <td>{item.Renewed}</td>
                                                        <td>{item.Product}</td>
                                                        <td>{item.Area}</td>
                                                        <td>{item.Landmark}</td>
                                                        <td>{item.LoanSegment}</td>
                                                        <td>{item.SchemeName}</td>
                                                        <td>{item.SimpleInterest}</td>
                                                        <td>{item.DealerName}</td>
                                                        <td>{item.LastDueDate}</td>
                                                        <td>{item.LastInstallmentPaidDate}</td>
                                                        <td>{item.LPICalculationType}</td>
                                                        <td>{item.LPInterest}</td>
                                                        <td>{moneyFormatIndia(item.DisbursementAmount)}</td>
                                                        <td>{item.DisbursementDate}</td>
                                                        <td>{item.DisbursementStatus}</td>
                                                        <td>{item.ModeOfRePayment}</td>
                                                        <td>{item.InvoiceNo}</td>
                                                        <td>{item.CreditScore}</td>
                                                        <td>{item.CKYCId}</td>
                                                        <td>{moneyFormatIndia(item.LoanAmount)}</td>
                                                        <td>{item.FinanceTags}</td>
                                                        <td>{item.region}</td>
                                                        <td>{item.fatherOrSpouse}</td>
                                                        <td>{item.fundingType}</td>
                                                        <td>{item.status}</td>
                                                        <td>{item.arrears}</td>
                                                        <td>{item.arrearReceived}</td>
                                                        <td>{item.prevAdvColl}</td>
                                                        <td>{item.monthTBC}</td>
                                                        <td>{item.monthTBCReceived}</td>
                                                        <td>{moneyFormatIndia(item.totalDues)}</td>
                                                        <td>{moneyFormatIndia(item.monthCollected)}</td>
                                                        <td>{moneyFormatIndia(item.grossTBC)}</td>
                                                        <td>{moneyFormatIndia(item.grossDemand)}</td>
                                                        <td>{moneyFormatIndia(item.grossCollected)}</td>
                                                        <td>{moneyFormatIndia(item.collDemandPercent)}</td>
                                                        <td>{moneyFormatIndia(item.receiptCount)}</td>
                                                        <td>{moneyFormatIndia(item.advanceColl)}</td>
                                                        <td>{item.emiDueCount}</td>
                                                        <td>{item.runningEmiCount}</td>
                                                        <td>{item.paidEmis}</td>
                                                        <td>{moneyFormatIndia(item.balanceEmis)}</td>
                                                        <td>{moneyFormatIndia(item.lpcDues)}</td>
                                                        <td>{item.bucket}</td>
                                                        <td>{item.lastRcvdDate}</td>
                                                        <td>{moneyFormatIndia(item.cummVas)}</td>
                                                        <td>{moneyFormatIndia(item.vasCollected)}</td>
                                                        <td>{moneyFormatIndia(item.vasDue)}</td>
                                                        <td>{moneyFormatIndia(item.cummHandloan)}</td>
                                                        <td>{moneyFormatIndia(item.handloanCollected)}</td>
                                                        <td>{moneyFormatIndia(item.handloanDue)}</td>
                                                        <td>{moneyFormatIndia(item.futurePrincipalDues)}</td>
                                                        <td>{moneyFormatIndia(item.totalFutureFinanceDue)}</td>
                                                        <td>{item.insurancePolicy}</td>
                                                        <td>{item.insuranceExpiry}</td>
                                                        <td>{item.seizeDate}</td>
                                                        <td>{item.registrationCertificate}</td>
                                                        <td>{item.smsEnabled}</td>
                                                        <td>{item.seizeStatus}</td>
                                                        <td>{item.ownerName}</td>
                                                        <td>{moneyFormatIndia(item.principalReceived)}</td>
                                                        <td>{moneyFormatIndia(item.interestReceived)}</td>
                                                        <td>{item.lpcCollected}</td>
                                                        <td>{item.lpcDiscount}</td>
                                                        <td>{item.hpEndorse}</td>
                                                        <td>{item.hpEndorseBy}</td>
                                                        <td>{item.totalPendingEmis}</td>
                                                        <td>{item.noOfDues}</td>
                                                        <td>{item.lastInstalmentPaidDate}</td>
                                                        <td>{item.lastEmiPaid}</td>
                                                        <td>{item.npa}</td>
                                                        <td>{item.legal}</td>
                                                        <td>{item.postcode}</td>
                                                        <td>{moneyFormatIndia(item.lastRcvdAmount)}</td>
                                                        <td>{moneyFormatIndia(item.lpcBalance)}</td>

                                                        <td className="text-right">{detailsDueApiResponse[24]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[26]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[32]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[34]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[35]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[36]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[44]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[45]}</td>
                                                        <td className="text-right">{detailsDueApiResponse[46]}</td>
                                                        <td className="text-right">{moneyFormatIndia(detailsDueApiResponse[60])}</td>
                                                        <td className="text-right">{detailsDueApiResponse[61]}</td>

                                                        <td className="text-nowrap">{item.cronRunDate}</td>

                                                        <td>
                                                            <div className="btn-group dropstart d-none" title="View Details">
                                                                <NavLink to={`/dashboard/allcloud-loans-report/${btoa(String(item.id))}/${btoa(String(item.AgreementNo))}`}>
                                                                    <i className="bi bi-eye" style={{ color: "black" }} />
                                                                </NavLink>
                                                                &nbsp;
                                                                <NavLink to={`/dashboard/allcloud-loans-report/${btoa(String(item.id))}/${btoa(String(item.AgreementNo))}/audit`}>
                                                                    <button className="btn btn-info text-white">Audit</button>
                                                                </NavLink>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                </>)}


                                            </>
                                        );
                                    })}


                                    {paginatedItems.length < 1 && (
                                        <>
                                            <tr>
                                                <td colSpan={paginatedItems.length-1} className='text-center py-3 fw-bold'>No record found</td>
                                            </tr>
                                        </>
                                    )}
                                </tbody>
                            </table>
                        ) : (<></>)}
                    </div>
                </div>

                {preloaderStatus && <Loader />}
            </Container>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className='modal-custom rounded'>
                    <div className='modal-head'>
                        <h4 id="modal-title">Upload Additional Data</h4>
                        <Button onClick={handleClose} color="primary" className=' btn'>
                            <i className="bi bi-x">&nbsp;</i>
                        </Button>
                    </div>
                    
                    <div className='modal-body'>
                        <div>
                            <form onSubmit={handleSubmit(handleFormSubmit)} encType="multipart/form-data">
                                <div className="row mx-0">
                                    <div className="col-md-6 px-0">
                                        <div className="form-group">
                                            <label htmlFor="itr_file">
                                                <span>File Upload <a style={{ color: "red" }}>*</a></span>
                                                <input
                                                    type='file'
                                                    id='itr_file'
                                                    name='itr_file'
                                                    className='form-control'
                                                    {...register('itr_file')}
                                                    onChange={handleItrFileChange}
                                                />
                                            </label>
                                        </div>
                                    </div>

                                    <div className="col-md-12 px-0">
                                        <div className="form-group mb-0">
                                            <button className="btn btn-primary mt-2"
                                            >
                                                Submit
                                            </button>
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
}

function mapStateToProps(state) {
    return {
        getBusinessDoneLoansData: state.getBusinessDoneLoansData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnachTransactionScheduling);