import { useState, useEffect ,useCallback} from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Helmet } from 'react-helmet-async';
import { Link, Link as RouterLink, useNavigate, useParams, useLocation, NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import QRCode from 'qrcode.react';

import Multiselect from 'multiselect-react-dropdown';
import "react-data-table-component-extensions/dist/index.css";
import * as React from 'react';
import $ from 'jquery';
import TableToExcel from '@linways/table-to-excel';
import DataTable from 'react-data-table-component'; 
// @mui
import { styled, useTheme } from '@mui/material/styles';
import { Button, Typography, Container, Box, Modal } from '@mui/material';
import * as userActions from "../../actions/userActions";
import config from "../../config";
import { encryptData } from '../../utils/cryptoUtils';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import '../common.scss';
import * as reportActions from "../../actions/reportActions";
import Loader from "../../components/Loader";
import LoanVersioning from './LoanVersioning';

import AccessDenied from '../common/AccessDenied';
import * as beatPlanActions from "../../actions/beatPlanActions";
import * as masterAction from "../../actions/masterAction";

// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
    maxWidth: 480,
    margin: 'auto',
    minHeight: '100vh',
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------
const EnachMandates = (props) => {
    const theme = useTheme();
    const { id } = useParams();
    const { urlAgreementNo } = useParams();
    const { brief } = useParams();
     const location = useLocation();
    const { actions, usersData } = props;
    const { register, handleSubmit, formState: { errors }, setValue, reset } = useForm();
    const navigate = useNavigate();

    const [permissions, setPermissions] = useState([]);
    const [userRoleType, setUserRoleType] = useState('User');
    const [loggeInUserID, setLoggeInUserID] = useState('');

    const [allLoggedinUserChild, setAllLoggedinUserChild] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [businessDoneLoans, setBusinessDoneLoans] = useState([]);
    const [loanDetailsEMIS, setLoanDetailsEMIS] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [searchQuery, setSearchQuery] = useState('');

    const [sendCrifOpenModal, setSendCrifOpenModal] = React.useState(false);
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [sendCrifFormData, setSendCrifFormData] = useState({ email_address: '' });
    const [preloaderStatus, setPreloaderStatus] = useState(false);

    const [viewModulePermissionStatus, setViewModulePermissionStatus] = useState(false);
    const [userHasPermission, setUserHasPermission] = useState(true);

    const [beatPlanLocation, setBeatPlanLocation] = useState([]);
    const [allCampaigns, setAllCampaigns] = useState([]);
    const [selectedVisitType, setSelectedVisitType] = useState([]);
    const [loanID, setLoanID] = useState('');
    const [loanDetailStatus, setLoanDetailStatus] = useState(false);
    const [activeTab, setActiveTab] = useState(0);
    const [versioningLoans, setVersioningLoans] = useState([]);
	const [businessDoneLoan, setBusinessDoneLoan] = useState([]);
    const [reportName, setReportName] = useState('');
    const [cronRunDateArr, setCronRunDateArr] = useState([]);
    const [cronRunTabsReloadStatus, setCronRunTabsReloadStatus] = useState(true);
    const [activeLoanTab, setActiveLoanTab] = useState(0);
    const [auditAllLoansButtonStatus, setAuditAllLoansButtonStatus] = useState(false);

    const [keyword, setKeyword] = useState('');

    const [userId, setUserId] = useState(''); 
    const[intentUrl, setIntentUrl] = useState('');
    const [totalOutstandingAmt, setTotalOutstandingAmt] = useState(0);
    const [loadDefaultQR, setLoadDefaultQR] = useState(false);
    const [formValues, setFormValues] = useState({
        amount: ''
    });
 	
    useEffect(() => {

        if (brief !== undefined ) {
            setReportName(brief); 
        }
        // check user is logged-in or not
        const authToken = localStorage.getItem('authToken');
        if (authToken === null) {
            navigate('/login');
        }

        if (id !== undefined ) {
            const idVal = atob(id);            
            setLoanID(idVal); 
             setLoanDetailStatus(true);
        } 
    }, [setLoanID,id,setReportName]);

    useEffect(() => {
        const obj = { 'start': '0', 'length': config.MAX_RECORDS };
        const requestData = encryptData(obj);
        const response = actions.getUserModuleData(requestData);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                const permissionsData = result.data.data;
                setPermissions(permissionsData);
                const hasViewPermissions = permissionsData.find(permission => permission.module_name === 'Audit Report')?.view_permission;
                setPermissions(permissionsData);
                setViewModulePermissionStatus(hasViewPermissions);
                setUserHasPermission(hasViewPermissions);

                if (Object.prototype.hasOwnProperty.call(result.data, 'user_info') && Object.prototype.hasOwnProperty.call(result.data.user_info[0], 'role_type') && result.data.user_info[0].role_type !== '') {
                    setUserRoleType(result.data.user_info[0].role_type);
                    // alert(result.data.user_info[0].role_type);
                    setLoggeInUserID(result.data.user_info[0].user_id);
                }
            }
        }).catch((error) => {
            // console.error("Promise rejected -  lead list:", error);
        });
    }, [setPermissions, setUserHasPermission, setUserRoleType, setLoggeInUserID]);

    const checkLogout = () => {
        const authToken = localStorage.getItem('authToken');
        const logInTimestamp = localStorage.getItem('logInTimestamp');

        if (logInTimestamp !== null) {
            const loginTimestamp = parseInt(logInTimestamp, 10);
            const currentTime = Math.floor(Date.now() / 1000);
            const timeElapsed = currentTime - loginTimestamp;
            const autoLogoutTimeInSecod = config.AUTO_LOGOUT_TIME_IN_SECONDS;

            if (timeElapsed >= autoLogoutTimeInSecod) {
                const params = { 'login_token': authToken };
                console.log("params : ", params);
                const requestData = encryptData(params);
                const response = actions.userLogout(requestData);
                response.then((result) => {
                    if (result.data.success === 1) {
                        const data = {};
                        actions.loadUserAuth(false);
                        actions.loginDataSuccess(data);
                        localStorage.setItem("authToken", '');
                        localStorage.setItem("logInTimestamp", '');
                        navigate("/login");
                    }
                }).catch((error) => {
                    console.log(error);
                });
            }
        }
    }

    useEffect(() => {
        checkLogout();
    });

    const handleKeywordChange = (event) => {
        setKeyword(event.target.value); 
    };
     
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleTabClick = (index) => {
        setActiveLoanTab(index); // Update active tab index
        const clickedDate = cronRunDateArr[index].formatted_cron_run_date;
        
        if(clickedDate !== ''){
            setPreloaderStatus(true);
            
            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'param': "audit_all_loans", 'clicked_date': clickedDate, 'keyword' : keyword   ,'brief' : 'view_transaction_scheduling'};
            const requestData = encryptData(params);
            const response = actions.getEnachMandatesData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  getEnachMandatesData:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    const results = result.data.data.records;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    console.log("All loans  = ",resultArr);

                    // const cronRunDateResults = result.data.data.cron_run_date;
                    // const cronRunDateResultArr = Object.keys(cronRunDateResults).map((key) => cronRunDateResults[key]);
                  //  setCronRunDateArr(cronRunDateResultArr);

                    setBusinessDoneLoans(resultArr);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                } else {
                    setPreloaderStatus(false);
                    setBusinessDoneLoans([]);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  getEnachMandatesData:", error);
            });
        }
    };

    const auditAllLoansButton = async () => {
        setPreloaderStatus(true);
        
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'param': "audit_all_loans", 'keyword' : keyword ,'brief' : 'view_transaction_scheduling'  };
        const requestData = encryptData(params);
        const response = actions.getEnachMandatesData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getEnachMandatesData:", result);
            if (result.data.success === 1) {
                setAuditAllLoansButtonStatus(true);
                setPreloaderStatus(false);
                const results = result.data.data.records;
                const resultArr = Object.keys(results).map((key) => results[key]);

                const cronRunDateResults = result.data.data.cron_run_date;
                const cronRunDateResultArr = Object.keys(cronRunDateResults).map((key) => cronRunDateResults[key]);
                 setCronRunDateArr(cronRunDateResultArr);

                setBusinessDoneLoans(resultArr);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
                setAuditAllLoansButtonStatus(false);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getEnachMandatesData:", error);
        });
    };

    useEffect(() => {
        setPreloaderStatus(true);

        const today = new Date();
        const todayFormatted = formatDate(today);

        setStartDate(todayFormatted);
        setEndDate(todayFormatted);

        setValue("start_date", todayFormatted);
        setValue("end_date", todayFormatted);

        // console.log("111111 - id = ",id);

        if (id !== undefined ) {
            setBusinessDoneLoans([]);
            setLoanDetailsEMIS([]);
            setVersioningLoans([]);
            const idVal = atob(id); 
            const agreementNo = atob(urlAgreementNo);  
            
            let reportName = '';
            if (brief !== undefined ) {
                reportName = brief; 
            }
            // alert(reportName);
            // alert(idVal);      
            setLoanID(idVal); 
             setLoanDetailStatus(true);
             setAuditAllLoansButtonStatus(true);
 
             const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted ,'loan_id' : idVal ,'loanAgreementNo' : agreementNo,'reportName' : reportName, 'keyword' : keyword ,'brief' : 'view_transaction_scheduling' };
                const requestData = encryptData(params);
                const response = actions.getEnachMandatesData(requestData, false);
                response.then((result) => {
                    // console.log("Promise resolved -  getEnachMandatesData:", result);
                    if (result.data.success === 1) {
                        setPreloaderStatus(false);
                        const results = result.data.data.records;
                        const resultArr = Object.keys(results).map((key) => results[key]);
                        setVersioningLoans(resultArr);

                        const defaultTabIndex = (resultArr.length >0) ? (resultArr.length - 1) : 0;
                        setActiveTab(defaultTabIndex);
                             
                        if (
                            results &&
                            results[0] &&
                            Object.prototype.hasOwnProperty.call(results[0], 'RepaymentSchedules')
                        ) {
                            // alert(results[0].AgreementNo);
                             setLoanDetailsEMIS(results[0].RepaymentSchedules);
                             setBusinessDoneLoan(results[0]);

                             setTotalOutstandingAmt(100);
                        }
                    } else {
                        setPreloaderStatus(false);
                        setBusinessDoneLoans([]);
                        setLoanDetailsEMIS([]);
                        setBusinessDoneLoan([]);
                    }
                }).catch((error) => {
                    setPreloaderStatus(false);
                    console.error("Promise rejected -  getEnachMandatesData:", error);
                });
        }else{
            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': todayFormatted, 'end_date': todayFormatted, 'keyword' : keyword ,'brief' : 'view_transaction_scheduling'  };
            const requestData = encryptData(params);
            const response = actions.getEnachMandatesData(requestData, false);
            response.then((result) => {
                // console.log("Promise resolved -  getEnachMandatesData:", result);
                if (result.data.success === 1) {
                    setPreloaderStatus(false);
                    const results = result.data.data.records;
                    const resultArr = Object.keys(results).map((key) => results[key]);

                    console.log("All loans  = ",resultArr);

                    setBusinessDoneLoans(resultArr);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                } else {
                    setPreloaderStatus(false);
                    setBusinessDoneLoans([]);
                    setLoanDetailsEMIS([]);
                    setBusinessDoneLoan([]);
                }
            }).catch((error) => {
                setPreloaderStatus(false);
                console.error("Promise rejected -  getEnachMandatesData:", error);
            });
        }

        
    }, [setBusinessDoneLoans,setLoanID,setLoanDetailsEMIS,setLoanDetailStatus,id, setBusinessDoneLoan,setVersioningLoans, setCronRunDateArr, setTotalOutstandingAmt]);

    const onSubmit = (data) => { 
         setPreloaderStatus(true);
        const params = {
            'keyword' : keyword,
            'brief' : 'enach_mandates',
            'start': '0',
            'length': config.MAX_RECORDS
        };

        const requestData = encryptData(params);
        const response = actions.getEnachMandatesData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  lead list:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.records;
                const resultArr = Object.keys(results).map((key) => results[key]);
                setBusinessDoneLoans(resultArr);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
            }
         }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  lead list:", error);
        });
    };

     

    const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
        setCurrentPage(1); // Reset to the first page when searching
    };

    const filteredItems = businessDoneLoans.filter(item => {
        const AgreementNo = item.AgreementNo?.toLowerCase() || '';
        const CustomerName = item.CustomerName?.toLowerCase() || '';
        // const CustomerNo = item.CustomerNo?.toLowerCase() || '';
        return (
            AgreementNo.includes(searchQuery.toLowerCase()) ||
            CustomerName.includes(searchQuery.toLowerCase())
            // ||
            // CustomerNo.includes(searchQuery.toLowerCase())
        );
    });

    const startIndex = (currentPage - 1) * config.HUNDREAD_PER_PAGE;
    const endIndex = startIndex + config.HUNDREAD_PER_PAGE;
    const paginatedItems = filteredItems.slice();
    const totalPages = Math.ceil(filteredItems.length / config.HUNDREAD_PER_PAGE);
     
    const exportLoansReport = async () => {
        const table = document.getElementById('exportLoansReportExcelFile');
        
        // Debugging log: Check if table is correctly selected
        console.log('Table:', table);
        
        const headersRow = table.querySelector('thead tr');
        const headers = Array.from(headersRow.querySelectorAll('th'));
        const actionColumnIndex = headers.findIndex(th => th.innerText.trim() === 'Action');
        
        // Debugging logs: Check headersRow and actionColumnIndex
        console.log('Headers Row:', headersRow);
        console.log('Action Column Index:', actionColumnIndex);
        
        if (actionColumnIndex !== -1) {
            // Debugging log: Check the header element to remove
            console.log('Header to Remove:', headers[actionColumnIndex]);
            
            headersRow.removeChild(headers[actionColumnIndex]);
        } else {
            console.log('Action column not found.');
        }
    
        TableToExcel.convert(table, {
            name: 'Audit-Report.xlsx',
            sheet: {
                name: 'Audit-Report',
            },
        });
    
        // Re-append the action column header if it was removed
        if (actionColumnIndex !== -1) {
            headersRow.appendChild(headers[actionColumnIndex]);
        }
    };

    const getLocation = (beatPlanId, btnAction, id) => {

        setPreloaderStatus(true);

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition((position) => {

                const latitude = position.coords.latitude;
                const longitude = position.coords.longitude;

                if(latitude !== "" && longitude !== ""){
            
                    const params = {
                        'id': id,
                        'beat_plan_id': beatPlanId,
                        'btnAction': btnAction,
                        'latitude': latitude,
                        'longitude': longitude
                    };
                    
                    const requestData = encryptData(params);
                    const response = actions.setBeatPlanStatus(requestData, false);
                    response.then((result) => {
                        if (result.data.success === 1) {
                            
                            toast.success(result.data.message, {
                                position: "top-right",
                            });
                            // setPreloaderStatus(false);
                            const params = { 'start': '0', 'length': config.MAX_RECORDS, 'start_date': startDate, 'end_date': endDate, 'keyword' : keyword ,'brief' : 'view_transaction_scheduling'  };
                            const requestData = encryptData(params);
                            const response = actions.getEnachMandatesData(requestData, false);
                            response.then((result) => {
                                // console.log("Promise resolved -  getEnachMandatesData:", result);
                                if (result.data.success === 1) {
                                    setPreloaderStatus(false);
                                    const results = result.data.data.records;
                                    const resultArr = Object.keys(results).map((key) => results[key]);
                                    setBusinessDoneLoans(resultArr);
                                } else {
                                    setPreloaderStatus(false);
                                    setBusinessDoneLoans([]);
                                }
                            }).catch((error) => {
                                setPreloaderStatus(false);
                                console.error("Promise rejected -  getEnachMandatesData:", error);
                            });
                        } else {
                            setPreloaderStatus(false);
                            toast.error(result.data.message, {
                                position: "top-right",
                            });
                        }
                    }).catch((error) => {
                        setPreloaderStatus(false);
                        console.error("Promise rejected -  setBeatPlanStatus: ", error);
                    });
                }

                setBeatPlanLocation({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude
                });
            }, (error) => {
                toast.error('Error getting location:', error, {
                    position: "top-right",
                });
            });
        } else {
            toast.error("Geolocation is not supported by this browser.", {
                position: "top-right",
            });
        }
    }

    useEffect(() => {
        if (cronRunDateArr.length > 0) {
            setActiveLoanTab(cronRunDateArr.length - 1);
        }
    }, [cronRunDateArr, setActiveLoanTab]);

    const goBackBtn = (event) => {
        setLoanDetailStatus(false);
        setAuditAllLoansButtonStatus(false);
        const url = `/dashboard/allcloud-loans-report`;
       // window.open(url, '_self');
        navigate(url);
      };

      const goBackBtnFromAuditAllLoans = (event) => {

        setPreloaderStatus(true);
        setAuditAllLoansButtonStatus(false);
        setCronRunDateArr([]);
        setBusinessDoneLoans([]);
        setLoanDetailsEMIS([]);
        setBusinessDoneLoan([]);
        
        const params = { 'start': '0', 'length': config.MAX_RECORDS, 'keyword' : keyword ,'brief' : 'view_transaction_scheduling' };
        const requestData = encryptData(params);
        const response = actions.getEnachMandatesData(requestData, false);
        response.then((result) => {
            // console.log("Promise resolved -  getEnachMandatesData:", result);
            if (result.data.success === 1) {
                setPreloaderStatus(false);
                const results = result.data.data.records;
                const resultArr = Object.keys(results).map((key) => results[key]);

                setBusinessDoneLoans(resultArr);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            } else {
                setPreloaderStatus(false);
                setBusinessDoneLoans([]);
                setLoanDetailsEMIS([]);
                setBusinessDoneLoan([]);
            }
        }).catch((error) => {
            setPreloaderStatus(false);
            console.error("Promise rejected -  getEnachMandatesData:", error);
        });
    };

      const viewDetails = (record) => {
        setLoanDetailsEMIS([]);
        setBusinessDoneLoan([]); 
        setLoanID(record.id); 
        setLoanDetailStatus(true);
        console.log("RepaymentSchedules = ",record.RepaymentSchedules);
        if (record && Object.prototype.hasOwnProperty.call(record, 'RepaymentSchedules')) {
            setLoanDetailsEMIS(record.RepaymentSchedules);
        }
        setBusinessDoneLoan(record);
      };

      const moneyFormatIndia = (number) => {
        if (number !== undefined && number !== null && !Number.isNaN(Number(number))) {
            number = parseFloat(number).toFixed(2);  // Ensure two decimal places
    
            const [integerPart, decimalPart] = number.split('.');
    
            const lastThreeDigits = integerPart.slice(-3);
            const otherDigits = integerPart.slice(0, -3);
    
            let formattedIntegerPart;
            if (otherDigits !== '') {
                formattedIntegerPart = `${otherDigits.replace(/\B(?=(\d{2})+(?!\d))/g, ',')},${lastThreeDigits}`;
            } else {
                formattedIntegerPart = lastThreeDigits;
            }
    
            return `${formattedIntegerPart}.${decimalPart}`;
        }
    
        return '0.00';  // Default return value for invalid inputs
    };
    
    const formatDateForValue = (date) => {
        if (!date || Number.isNaN(new Date(date).getTime())) {
            return '';  // Return an empty string for invalid or empty dates
        }
    
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = String(newDate.getMonth() + 1).padStart(2, '0');
        const day = String(newDate.getDate()).padStart(2, '0');
    
        return `${day}-${month}-${year}`;
    };

    const columns = [
       
        {
            name: 'Lead Name',
            selector: row => row.LeadName,
            sortable: true,
        },
        
        {
            name: 'Lead Mobile',
            selector: row => row.LeadMobile,
            sortable: true,
        },
        
        {
            name: 'E-Nach Status',
            selector: row => row.response_status,
            sortable: true,
            cell: row => (
                <span
                    style={{
                        color: row.response_status === 'Success' ? 'green' :
                               (row.response_status  === 'Aborted' ) ? 'orange' : 'red'
                    }}
                >
                    {row.response_status}
                </span>
            ),
        },
        {
            name: 'Reason',
            selector: row => row.txn_err_msg,
            sortable: true,
            cell: row => (
                <span
                    style={{
                        color: row.response_status === 'Success' ? 'green' :
                               (row.response_status  === 'Aborted' ) ? 'orange' : 'red'
                    }}
                >
                    {row.txn_err_msg}
                </span>
            ),
        },
        {
            name: 'Sheduling Status',
            selector: row => row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus || 'N/A',
            sortable: true,
            cell: row => (
                <span
                    style={{
                        color: row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus  === 'Success' ? 'green' :
                               (row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus  === 'Initiated' ) ? 'orange' : 'red'
                    }}
                >
                    {row.transaction_scheduling_data?.LatestEnachTransactionSchedulingStatus || 'N/A'}
                </span>
            ),
        },


        {
            name: 'Verification Status',
            selector: row => row.transaction_verification_data?.LatestEnachTransactionVerificationStatus || 'N/A',
            sortable: true,
            cell: row => (
                <span
                    style={{
                        color: row.transaction_verification_data?.LatestEnachTransactionVerificationStatus === 'Success' ? 'green' :
                               (row.transaction_verification_data?.LatestEnachTransactionVerificationStatus  === 'Initiated' ) ? 'orange' : 'red'
                    }}
                >
                    {row.transaction_verification_data?.LatestEnachTransactionVerificationStatus || 'N/A'}
                </span>
            ),
        },

        {
            name: 'Sent By',
            selector: row => row.createdBy,
            sortable: true,
        },
        

        {
            name: 'Sent On',
            selector: row => row.created_date,
            sortable: true,
        },
        

 
    ];

    // Conditional row styles to change text color
        const conditionalRowStyles = [
            {
            when: row => row.response_status === 'Success-',   
            style: {
                color: 'green',
            },
            },
            {
            when: row => row.response_status === 'Aborted-',  
            style: {
                color: 'red',
            },
            },
            {
            when: row => row.response_status === 'Failed-',  
            style: {
                color: 'red',
            },
            },
        ];

    const ExpandedComponent = ({ data }) => (
        <div className='row px-2 py-2'>

{data && Object.prototype.hasOwnProperty.call(data, 'proposed_amount') && (
            <div className="col-md-6" >
                <p>
                <strong>E-NACH Proposed Amount : </strong> 
                <span className="text-success">{data.proposed_amount}
                </span></p>
            </div>
            )}

           {data && Object.prototype.hasOwnProperty.call(data, 'proposed_debit_date') && (
            <div className="col-md-6" >
                <p>
                <strong>E-NACH Proposed Debit Date : </strong> 
                <span className="text-success">{formatDateForValue(data.proposed_debit_date)}</span>
                </p>
            </div>
            )} 


            <div className="col-md-6"><p><strong>Account Holder Name : </strong> {data.accountHolderName}</p></div>
            <div className="col-md-6"><p><strong>account Type : </strong> {data.accountType}</p></div>
            <div className="col-md-6"><p><strong>Account Number : </strong> {data.account_number}</p></div>
            <div className="col-md-6"><p><strong>Amount : </strong> {data.amount} </p> </div>


            <div className="col-md-6"><p><strong>UMRN Number : </strong> {data.UMRNNumber} </p> </div>
            <div className="col-md-6"><p><strong>Expiry date : </strong> {data.expiry_date} </p> </div>
            <div className="col-md-6"><p><strong>Frequency : </strong> {data.frequency} </p> </div>
            <div className="col-md-6"><p><strong>IFSCCode : </strong> {data.IFSCCode} </p> </div>
            <div className="col-md-6"><p><strong>Amount Type : </strong> {data.amount_type} </p> </div>
            <div className="col-md-6"><p><strong>Ifsc code : </strong> {data.ifsc_code} </p> </div>
            <div className="col-md-6"><p><strong>Identifier : </strong> {data.identifier} </p> </div>
            <div className="col-md-6"><p><strong>Schedule date : </strong> {data.schedule_date} </p> </div>
            <div className="col-md-6"><p><strong>Debit Day : </strong> {data.debitDay} </p> </div>
            <div className="col-md-6"><p><strong>Debit Flag : </strong> {data.debitFlag} </p> </div>
            <div className="col-md-6"><p><strong>AadharNo : </strong> {data.aadharNo} </p> </div>
            <div className="col-md-6"><p><strong>Date Of Birth : </strong> {data.dateOfBirth} </p> </div>
            <div className="col-md-6"><p><strong>Mandate Purpose : </strong> {data.mandatePurpose} </p> </div>
            <div className="col-md-6"><p><strong>UtilityNo : </strong> {data.utilityNo} </p> </div>
            <div className="col-md-6"><p><strong>Help desk No : </strong> {data.helpdeskNo} </p> </div>
            <div className="col-md-6"><p><strong>Help desk Email : </strong> {data.helpdeskEmail} </p> </div>
            <div className="col-md-6"><p><strong>Pan : </strong> {data.pan} </p> </div>
            <div className="col-md-6"><p><strong>Mobile : </strong> {data.mobile} </p> </div>
            <div className="col-md-6"><p><strong>Email : </strong> {data.email} </p> </div>
        </div>
    );

    const [open, setOpen] = React.useState(false);
    const btnOpenUploadMissingData = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setSelectedItrFiles([]); // Reset selected ITR files
    };
    const [selectedItrFiles, setSelectedItrFiles] = useState([]);

    const handleItrFileChange = (e) => {
        const files = Array.from(e.target.files);
        const base64Files = [];

        files.forEach((file) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const base64Data = event.target.result;
                const fileName = file.name;
                base64Files.push({ file: base64Data, name: fileName });
                if (base64Files.length === files.length) {
                    setSelectedItrFiles(base64Files);
                }
            };
            reader.readAsDataURL(file);
        });
    };

    

    const handleFormSubmit = (data) => {

        if (!selectedItrFiles || selectedItrFiles.length === 0) {
            toast.error("No file has been selected.", {
                position: "top-right",
            });
        } else {

            const params = {"upload_file": selectedItrFiles };

            // setPreloaderStatus(true);
            const requestData = encryptData(params);
            const response = actions.uploadMissingData(requestData, navigate);

            response.then((result) => {
                // console.log("Promise resolved - uploadLeads form:", result); 
                if (result.data.success === 1) {
                    toast.success(result.data.message, {
                        position: "top-right",
                    });
                    setPreloaderStatus(false);
                    reset();
                    setOpen(false);
                    setSelectedItrFiles([]);
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                    setPreloaderStatus(false);
                }
            }).catch((error) => {
                toast.error(error, {
                    position: "top-right",
                });
            });
        }
    };

    const defaultQRCall = useCallback(async () => {

        if( !loadDefaultQR && totalOutstandingAmt > 0){
            const params = { 'amount':totalOutstandingAmt, "AgreementNo" : businessDoneLoan.AgreementNo };
            if (userId !== '') {
                params.user_id = userId;
            }
        
            setIntentUrl('');
            // console.log("form data = ", params);
            const requestData = encryptData(params);            
            try {
                const result = await actions.generateQRcode(requestData);
                setLoadDefaultQR(true);                
                if (result.data.success === 1) {
                    if (result.data.intent_url !== '') {
                        setIntentUrl(result.data.intent_url);
                        toast.success("QR code generated successfully.", {
                            position: "top-right",
                        });
                    } else {
                        toast.error("You can't generate QR code, Please try again!", {
                            position: "top-right",
                        });
                    }
                } else {
                    toast.error(result.data.message, {
                        position: "top-right",
                    });
                }
            } catch (error) {
                toast.error(error.message || "An error occurred", {
                    position: "top-right",
                });
            }
        }
    }, [userId, formValues, loadDefaultQR, totalOutstandingAmt]);
    
    useEffect(() => {
        if (!loadDefaultQR) {
           defaultQRCall();
        }
    }, [loadDefaultQR, defaultQRCall, totalOutstandingAmt]);

    
    const handleSubmitForm = (data) => {
        const params = { 'amount':  formValues.amount, "AgreementNo" : businessDoneLoan.AgreementNo };
        if (userId !== '') {
            params.user_id = userId;
        }
        setIntentUrl('');
        // console.log("form data = ", params);
        const requestData = encryptData(params);
        const response = actions.generateQRcode(requestData);
        response.then((result) => {
             if (result.data.success === 1) {
                if (result.data.intent_url !== '') {  
                    setIntentUrl(result.data.intent_url);  
                    toast.success("QR code generated sucessfully.", {
                        position: "top-right",
                    });
                } else {
                    toast.error("You can't generate QR code, Please try again!", {
                        position: "top-right",
                    });
                }
            } else {
                toast.error(result.data.message, {
                    position: "top-right",
                });
            }
        }).catch((error) => {
             toast.error(error, {
                position: "top-right",
            });
        });
    };   
    const handleChange = (e) => { 
       // setIntentUrl('');
        const { name, value } = e.target;      
        setFormValues({
            ...formValues,
            [name]: value,
        });         
    };

    if (!userHasPermission) {
        // Redirect or render an access denied message
        return <AccessDenied />;
    }

   
    return (
        <>  
            <Helmet>
                <title>E-NACH Mandates | Ckers</title>
            </Helmet>

            <Container>
                
                <div id='main-wrap'  className={loanDetailStatus ? "d-none mb-3 mmt-7 filter" : "mb-3 mmt-7 filter d-none"}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className='row mx-0'>

                            <div className='col'>
                                <div className='form-group'>
                                    <label htmlFor="keyword">
                                        <span>Keyword</span>
                                        <input type='text' name='keyword' id='keyword' className='form-control'{...register('keyword')} placeholder="Enter your value here..." onChange={handleKeywordChange}/>
                                    </label>
                                </div>
                            </div>

                             
                            <div className='col'>
                                <div className='form-group mb-2'>
                                    <span className='d-block'>&nbsp;</span>
                                    <button className='btn btn-primary'>Search</button>
                                </div> 
                            </div> 
                        </div>
                    </form>
                </div>

                <div id="main-wrap">
                    <div className="row justify-content-between"> 
                        <div className="col-md-7">
                            <Typography className="line-initial" variant="h4" sx={{ mb: 0 }}>
                            E-NACH Mandates
                            </Typography>  
                        </div>  
                    </div> 
                        <DataTable
                            // title="My DataTable with Expandable Rows"
                            columns={columns}
                            data={businessDoneLoans}
                            expandableRows
                            expandableRowsComponent={ExpandedComponent}
                            conditionalRowStyles={conditionalRowStyles}
                            // pagination
                        /> 
                </div>  
                {preloaderStatus && <Loader />}
            </Container> 
        </>
    );
}

function mapStateToProps(state) {
    return {
        getEnachMandatesData: state.getEnachMandatesData,
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(Object.assign(reportActions.default, userActions.default, beatPlanActions.default, masterAction.default), dispatch),
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(EnachMandates);